import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, {Component} from 'react';
import './index.scss';
import HomeTwo from './HomeTwo';
import About from './pages/About';
//import BlogLeftSidebar from './blog/BlogLeftSidebar';
// import BlogRightSidebar from './blog/BlogRightSidebar';
// import BlogDetailsLeftSidebar from './blog/BlogDetailsLeftSidebar';
// import BlogDetailsRightSidebar from './blog/BlogDetailsRightSidebar';
import Contact from './pages/Contact';
import PageNotFound from './pages/404';
import NoMAtch from './pages/404';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import AboutFounder from './pages/AboutFounder';
import AboutAuthor from './pages/AboutAuthor';
import OurBooks from './service/OurBooks';
import OurBookDetails from './service/OurBookDetails';
import AboutDetails from './pages/AboutDetails';
import PriceList from './pages/PriceList';
import WhereToBuy from './pages/WhereToBuy';
import WheretobuylistDetails from './pages/WheretobuylistDetails';
import Ebooks from './pages/Ebooks';




class App extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <Switch>
                    <Route exact path={`/`} component={HomeTwo}/>
                    <Route exact path={`/about-us`} component={About}/>
                    <Route exact path={`/about-founder`} component={AboutFounder}/>
                    <Route exact path={`/about-author`} component={AboutAuthor}/>
                    <Route exact path={`/aboutdetails/:slug`} component={AboutDetails}/>
                    <Route exact path={`/ourbooks`} component={OurBooks}/>
                    <Route exact path={`/ourbookdetails/:slug`} component={OurBookDetails}/>
                    <Route exact path={`/where-to-buy`} component={WhereToBuy}/>
                    <Route exact path={`/wheretobuydetails/:slug`} component={WheretobuylistDetails}/>
                    <Route exact path={`/price-list`} component={PriceList}/>
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/blog-left-sidebar`} component={BlogLeftSidebar}/> */}
                    <Route exact path={`/ebooks`} component={Ebooks}/>
                    <Route exact path={`/contact-us`} component={Contact}/>
                    <Route exact path={`/404`} component={PageNotFound}/>
                    <Route component={NoMAtch} />
                </Switch>                
            </BrowserRouter>
        )
    }
}

export default App;