import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import {Link} from 'react-router-dom'
import Client from '../Client'
import marked from 'marked' //to make font bold and headings
import BlackLoading from '../assets/images/black-loader.gif'

class WheretobuylistDetails extends Component{
    constructor(props) {
        super(props)
    
        this.state = {
             article:[]
        }
    }

    componentDidMount(){
        const slug = this.props.match.params.slug
        if(slug){
            Client.getEntries({
                'content_type': 'whereToBuyList', 
                'fields.slug': slug
    
            }).then((entries)=>{
                this.setState({article: entries.items[0]})
            })

        }
        
    }

    //to make font bold and headings
    getParsedMarkdown(bookDescription){
        return{
            __html:marked(bookDescription, {sanitize:true})
        }
    }
    //to make font bold and headings - end
    
    redirectToTarget=()=>{
        this.props.history.push('/where-to-buy')
    }

    render(){

        return(
            <div>
                {/* Navigation bar */}
                <NavBar/>

                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                <h1>{this.state.article.length === 0 ?
                                        <div align="center"><img src={BlackLoading} alt="Loading..." /></div> :
                                    <div>
                                        <div>
                                            Organisation Name: {this.state.article.fields.whereToBuyTitle}
                                        </div>
                                        </div>
                                    }</h1>
                                    <ul className="page-breadcrumb">
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to={`${process.env.PUBLIC_URL}/where-to-buy`}>Where to Buy</Link></li>
                                        <li>Where to Buy Details</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                <div className={`brand-logo-slider-area section-space--inner--50 ${this.props.background}`}>
                    {/*Service section start*/}
                    <div className="service-section">
                        <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-12 order-1">
                            <div className="service-details">
                                {/* service gallery */}
                                {/* <ServiceGallery/> */}
                                <div className="content section-space--top--30">
                                <div className="row">
                                    <div className="col-12">
                                    {this.state.article.length === 0 ?
                                        <div align="center"><img src={BlackLoading} alt="Loading..." /></div> :
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h4>Address:</h4> <p style={{textAlign:'justify'}} dangerouslySetInnerHTML = 
                                            {this.getParsedMarkdown(this.state.article.fields.whereToBuyAddress)}></p>
                                            <h4>Email:</h4> <p style={{textAlign:'justify'}} dangerouslySetInnerHTML = 
                                            {this.getParsedMarkdown(this.state.article.fields.whereToBuyEmail)}></p>
                                            <h4>Phone No.:</h4> <p style={{textAlign:'justify'}} dangerouslySetInnerHTML = 
                                            {this.getParsedMarkdown(this.state.article.fields.whereToBuyPhone)}></p>
                                            <h4>WhatsApp:</h4> <p style={{textAlign:'justify'}} dangerouslySetInnerHTML = 
                                            {this.getParsedMarkdown(this.state.article.fields.whereToBuyWhatsapp)}></p>
                                            <h4>Fax:</h4> <p style={{textAlign:'justify'}} dangerouslySetInnerHTML = 
                                            {this.getParsedMarkdown(this.state.article.fields.whereToBuyFax)}></p>
                                            <h4>Website:</h4> <p style={{textAlign:'justify'}} dangerouslySetInnerHTML = 
                                            {this.getParsedMarkdown(this.state.article.fields.whereToBuyWebsite)}></p>
                                            <p><button className="btn btn-primary"><Link to="#" onClick={this.redirectToTarget}>Back to Where to Buy</Link></button></p>
                                            <p>..</p>
                                            
                                        </div>
                                        </div>
                                    }
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            {/* <div className="col-lg-4 col-12 order-2">
                                <Sidebar />
                            </div> */}
                        </div>
                        </div>
                    </div>
                    {/*Service section end*/}
                    </div>

                {/* Brand logo */}
                {/* <BrandLogoSlider background = "grey-bg" /> */}

                {/* Footer */}
                <Footer/>

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
        )
    }
}


export default WheretobuylistDetails;