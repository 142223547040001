import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Client from '../Client'
import {Link} from 'react-router-dom'

class ServiceTabExample extends Component{

    constructor(props) {
        super(props)
        this.state = {
            aboutuspage: []
        }
    }

    componentDidMount(){
        Client.getEntries({
            'content_type': 'aboutUs', 
            'order': 'sys.createdAt'

        }).then((entries)=>{
            this.setState({aboutuspage: entries.items})
        })
    }

    render(){
        
        /* service tab menu */
        let serviceTabMenuData = [
            {tabMenuName: 'ABOUT VIJAYA PUBLICATIONS'},
            {tabMenuName: 'ABOUT THE FOUNDER'},
            {tabMenuName: 'ABOUT THE AUTHOR'},
            // {iconName: 'flaticon-010-tank-1', tabMenuName: 'Power and Energy'}
        ];

        let serviceTabMenuDatalist = serviceTabMenuData.map((val, i)=>{
            return(
                <Tab key={i}>  <span className="text">{val.tabMenuName}</span></Tab>
            )
        });

        
        /* service tab content */
        
        let serviceTabContentData = [
            {bgUrl: "service-tab1.jpg", contentTitle: 'ABOUT VIJAYA PUBLICATIONS', contentDesc: 'Vijaya Publications is a sole proprietary concern founded by Mrs Vijaya Harry in 1976. After her sad demise in January 2009, the proprietorship passed on to her daughter-in-law Mrs Prema Subramaniam.', serviceLink: 'about-us'},
            {bgUrl: "service-tab1.jpg", contentTitle: 'ABOUT THE FOUNDER', contentDesc: 'MRS. VIJAYA HARRY (11TH APRIL 1926 TO 11TH JANUARY 2009) Vijaya was born on 11th April 1926 in Perinkulam, a tiny village in Tamil Nadu, in an illustrious family of academicians.', serviceLink: 'about-founder'},
            {bgUrl: "service-tab1.jpg", contentTitle: 'ABOUT THE AUTHOR', contentDesc: 'CAPT. H. SUBRAMANIAM F.R.Met.S., M.R.I.N., F.N.I., F.C.M.M.I., M.I.Mar.Tech., M.I.Met.S., Extra Master (UK) Capt. Subramaniam was born in 1942 in Ootacamund, in the Nilagiri Hills of South India.', serviceLink: 'about-author'},
            // {bgUrl: "service-tab1.jpg", contentTitle: 'Power and Energy', contentDesc: 'Lorem ipsum dolor sit amet, consectet adipisicin elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', serviceLink: 'service-details-left-sidebar'}
        ];

        let serviceTabContentDatalist = serviceTabContentData.map((val, i)=>{
            return(
                <TabPanel key={i}>
                    <div className="service-tab__single-content-wrapper" style={{ backgroundColor: `gray` }}>
                        <div className="service-tab__single-content">
                            <h3 className="service-tab__title">{val.contentTitle}</h3>
                            <p className="service-tab__text">{val.contentDesc}</p>
                            <Link to={`${process.env.PUBLIC_URL}/${val.serviceLink}`} className="see-more-link">SEE MORE</Link>
                        </div>
                    </div>
                </TabPanel>
            )
        });

        return(
            <div>

                {/*====================  service tab area ====================*/}
                <div className="service-tab-area section-space--inner--50">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title-area text-center">
                        <h2 className="section-title section-space--bottom--50">About Us</h2>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        {/* service tab wrapper */}
                        
                        <div className="service-tab-wrapper">
                        <Tabs>
                            <div className="row no-gutters">
                                <div className="col-md-4">
                                    <TabList>
                                        <div className="service-tab__link-wrapper">
                                            {serviceTabMenuDatalist}
                                        </div>
                                    </TabList>
                                </div>

                                <div className="col-md-8">
                                    {serviceTabContentDatalist}
                                </div>
                            </div>
                        </Tabs>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/*====================  End of service tab area  ====================*/}
            </div>
        )
    }
}

export default ServiceTabExample;