import React, {Component} from 'react';
import Swiper from 'react-id-swiper';
import Client from '../Client'
//import {Link} from 'react-router-dom'

class BrandLogoSlider extends Component{
    constructor(props) {
        super(props)
        this.state = {
            booklinkpage: []
        }
    }

    componentDidMount(){
        Client.getEntries({
            'content_type': 'booklinked'

        }).then((entries)=>{
            this.setState({booklinkpage: entries.items})
        })
    }

    render(){

        const params = {
            slidesPerView : 4,
            loop: true,
            speed: 1000,
            spaceBetween : 30,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
           
            // Responsive breakpoints
            breakpoints: {
                1499:{
                    slidesPerView : 4
                },

                991:{
                    slidesPerView : 3
                },

                767:{
                    slidesPerView : 3

                },

                575:{
                    slidesPerView : 2
                }
            }
        }

        let data = [
            {img: './assets/img/thumb/kindle-book-1.jpg', logoLink: 'https://www.amazon.com/gp/product/B082XB2666?notRedirectToSDP=1&ref_=dbs_mng_calw_0&storeType=ebooks', ebook: 'eBook-1 Kindle Edition'},
            {img: './assets/img/thumb/kindle-book-2.jpg', logoLink: 'https://www.amazon.com/gp/product/B082XB2666?notRedirectToSDP=1&ref_=dbs_mng_calw_0&storeType=ebooks', ebook: 'eBook-2 Kindle Edition'},
            {img: './assets/img/thumb/kindle-book-3.jpg', logoLink: 'https://www.amazon.com/gp/product/B07XLMLCWF?notRedirectToSDP=1&ref_=dbs_mng_calw_1&storeType=ebooks', ebook: 'eBook-3 Kindle Edition'},
            {img: './assets/img/thumb/kindle-book-4.jpg', logoLink: 'https://www.amazon.in/Ship-Stability-Operational-Level-Nutshell-ebook/dp/B07WRQKLJM', ebook: 'eBook-4 Kindle Edition'},
            {img: './assets/img/thumb/kindle-book-5.jpg', logoLink: 'https://www.amazon.in/Bridge-Equipment-Charts-Publications-Nutshell-ebook/dp/B07YYK4XPV', ebook: 'eBook-5 Kindle Edition'},
            {img: './assets/img/thumb/kindle-book-6.jpg', logoLink: 'https://www.amazon.in/Ship-Stability-Management-Level-Nutshell-ebook/dp/B07WTVH9XR', ebook: 'eBook-6 Kindle Edition'},
            {img: './assets/img/thumb/kindle-book-7.jpg', logoLink: 'https://www.amazon.in/Nautical-Watchkeeping-Capt-Harry-Subramaniam-ebook/dp/B08BZWCDS6', ebook: 'eBook-7 Kindle Edition'},
            {img: './assets/img/thumb/kindle-book-8.jpg', logoLink: 'https://www.amazon.in/Spherical-Trigonometry-Capt-Harry-Subramaniam-ebook/dp/B07WTVF32C', ebook: 'eBook-8 Kindle Edition'},
        ];

        let DataList = data.map((val, i)=>{
            return(
                <div className="swiper-slide brand-logo-slider__single" key={i}>
                    <div className="image text-center">
                        <a href={val.logoLink} target={'_blank'}>
                            <img src={val.img} className="img-fluid" alt="" />
                            <strong>{val.ebook}</strong>
                        </a>
                        

                        {/* <a href={val.fields.bookLinkAmazon}>
                            <img src={val.fields.booklinkImg.fields.file.url} className="img-fluid" alt="" />
                        </a> */}
                    </div>
                </div>
            )
        });


        return(
            <div>
                {/*====================  brand logo area ====================*/}
                <div className={`brand-logo-slider-area  ${this.props.background}`}>
                <div className="container">
                    <div className="row">
                    <div className="col-lg-12">
                        {/* brand logo slider */}
                        <div className="brand-logo-slider__container-area">
                            <Swiper {...params}>
                                {DataList}
                            </Swiper>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/*====================  End of brand logo area  ====================*/}
            </div>
        )
    }
}

export default BrandLogoSlider;