import React, {Component} from 'react';
import Client from '../Client'
import {Link} from 'react-router-dom'

class WheretobuyList extends Component{
    constructor(props) {
        super(props)
        this.state = {
            wheretobuylistpage: []
        }
    }

    componentDidMount(){
        Client.getEntries({
            'content_type': 'whereToBuyList', 
            'order': 'sys.createdAt'

        }).then((entries)=>{
            this.setState({wheretobuylistpage: entries.items})
        })
    }

    render(){

        let Datalist = this.state.wheretobuylistpage.map((val, i) => {
            return(
                <div className="col-lg-4 col-md-4 col-12 section-space--bottom--30" key={i}>
                    <div className="service-grid-item">
                    <div className="service-grid-item__image">
                        <div className="service-grid-item__image-wrapper">
                        {/* <Link to={`${process.env.PUBLIC_URL}/ourbookdetails/${val.fields.slug}`}>
                            <img src={val.fields.bookimg.fields.file.url} className="img-fluid" alt="Nutshell Series Books" />
                        </Link> */}
                        </div>
                    </div>
                    <div className="service-grid-item__content" style={{ backgroundColor: `lightyellow`, margin: `10px`, padding: `10px`}}>
                        {/* <h3 className="title">
                        <Link to={`${process.env.PUBLIC_URL}/wheretobuydetails/${val.fields.slug}`}>{val.whereToBuyTitle}</Link>
                        </h3> */}
                        <h3 className="subtitle">{val.fields.whereToBuyTitle}</h3>
                        <p className="subtitle"><span></span> {val.fields.whereToBuyAddress}</p>
                        <Link to={`${process.env.PUBLIC_URL}/wheretobuydetails/${val.fields.slug}`} className="see-more-link">SELLER DETAILS</Link>
                    </div>
                    </div>
                </div>
            )
        });

        return(
            <div>

                {/*====================  service page content ====================*/}
                <div className="page-wrapper section-space--inner--50">
                {/*Service section start*/}
                <div className="service-section">
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                        <div className="service-item-wrapper">
                            <div className="row">
                                {Datalist}
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/*Service section end*/}
                </div>

                {/*====================  End of service page content  ====================*/}

            </div>
        )
    }
}

export default WheretobuyList;