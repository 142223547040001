import React, { Component } from 'react';
import Swiper from 'react-id-swiper';

class TestimonialSlider extends Component{

    render(){
        const params = {
            slidesPerView : 1,
            loop: true,
            speed: 1000,
            effect: 'fade',
            autoplay: {
                delay: 3000
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            renderPagenation: () => (
                <div className="swiper-pagination"></div>
              ),
        }
        let data = [
            {testimonialImage: "", testimonialDate:"18th May 1976", testimonialName: "J.C. ANAND", testimonialDesignation: "President - INDIAN NATIONAL SHIPOWNERS ASSOCIATION", testimonialText: "The excellent treatment and layout of the subject gives scope for firm grounding in the science of navigation, which is so very necessary for the navigator as he has in his care, safe conduct of a modern sophisticated vessel costing crores of Rupees of capital investment and carrying valuable cargoes worth many more crores, besides the reputation of the owners and safety of several lives on board."},
            {testimonialImage: "", testimonialDate:"-", testimonialName: "Capt H Subramaniam", testimonialDesignation: "-", testimonialText: "Teaching at pre-sea academies gives me more satisfaction than lecturing at post sea colleges as the young cadets look up to you as a role model, an icon or even a hero. They tend to think One day I hope to be like him, barring the current waistline!"},
            {testimonialImage: "", testimonialDate:"16th May 1977", testimonialName: "Prof. A.K. Mukherjee", testimonialDesignation: "Director - Regional Meteorological Centre, Mumbai.", testimonialText: "Weather is a question of life and death for mariners. It is here that the value of this book lies. Mariners have to learn the subject of Meteorology by heart because it is so important for them. This book presents to mariners, in a crisp as well as lucid manner, the necessary knowledge of the subject. With the author’s wide experience, at sea and also in teaching merchant navy officers at the L.B.S. Nautical and Engineering College, combined with his personal knowledge of the background of students in India, he has written a good text book suitable for mariners."},
            {testimonialImage: "", testimonialDate:"29th November 1991", testimonialName: "CAPT. P.S. BARVE", testimonialDesignation: "Nautical Adviser to the Govt of India", testimonialText: "Capt. Subramaniam's reputation as an excellent teacher is well known. So, it is little wonder that all his six earlier books have been great successes internationally. I am sure that this book, his seventh, shall not only be a good textbook for competency examinations but would be a handy reference book for watchkeeping officers on board ships."},
            {testimonialImage: "", testimonialDate:"1st January 1980", testimonialName: "Capt. Indrajit Singh", testimonialDesignation: "Captain Superintendent – Training Ship 'Rajendra'", testimonialText: "With lucid explanations of True Motion and True Plotting along with clear sketches and diagrams, the book is bound to be of immense value to the officers studying for Certificates of Competency Examinations and to all those desirous of mastering the use of radar in ships."},
            {testimonialImage: "", testimonialDate:"13th September 1981", testimonialName: "Capt. R.D. Kohli", testimonialDesignation: "Executive Director, Shipping Corporation of India Ltd., Vice Chairman, Narottom Morarjee Institute of Shipping.", testimonialText: "Capt. Subramaniam has attempted, and I would say succeeded, in combining the theory and practical application of stability. The book closely follows the best approach. Starting from the very basics, or ‘beginning at the very beginning’, the book brings the student steadily up to the required level in such a manner that he can study it by himself, whilst out at sea, hardly needing any other assistance."},
            {testimonialImage: "", testimonialDate:"15th February 1986", testimonialName: "Capt/Dr. P.S. VANCHISWAR", testimonialDesignation: "Resident Professor- World Maritime University", testimonialText: "Enthusiasm, perseverance & dedication are the qualities that come to my mind when I think of Captain H. Subramaniam. Enthusiasm because of the way in which he goes about, not only his work, but also his ‘hobby’ of writing technical books!  Perseverance because of his starting to write the next book as soon the earlier one is complete, thereby writing six books in less than ten years! Dedication to his work because he does not let his book writing interfere with his main occupation – teaching. In fact, I have heard from those who have had the good fortune to have been his students, that his dedication to teaching is total."},
            {testimonialImage: "", testimonialDate:"21st April 1994", testimonialName: "Capt. S.S. Naphade", testimonialDesignation: "Nautical Adviser to the Govt of India", testimonialText: "Capt Subramaniam has over twenty five years of experience in teaching mariners at various levels from NWKO to Extra Master. During this quarter of a century, he has earned a reputation as an excellent teacher and a strict disciplinarian. That is because he has taught with devotion and yet carried his great learning lightly. He feels that a teacher must not only transfer knowledge but also instill a proper code of conduct in the student."},
        ];

        let DataList = data.map((val, i)=>{
            return(
                <div className="swiper-slide testimonial-slider__single-slide" key={i}>
                    <div className="author">
                        
                        {/* <div className="author__image"> */}
                        {/* <img src={val.fields.testimonialImage.fields.file.url} className="img-fluid" alt="" /> */}
                        {/* </div> */}
                        <div className="author__details">
                        <h4 className="name">{val.testimonialName}</h4>
                        <div className="designation">{val.testimonialDesignation}</div>
                        <div >{val.testimonialDate}</div>
                        </div>
                    </div>
                    <div className="content">
                        {val.testimonialText}
                    </div>
                </div>
            )
        });
        
        return(
            <div>
                {/*====================  testimonial slider area ====================*/}
                <div className="testimonial-slider-area testimonial-slider-area-bg section-space--inner--50">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="testimonial-slider">
                        <div className="testimonial-slider__container-area">
                            <Swiper {...params}>
                                {DataList}
                            </Swiper>
                            <div className="swiper-pagination"></div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/*====================  End of testimonial slider area  ====================*/}

            </div>
        )
    }
}

export default TestimonialSlider;