import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import {Link} from 'react-router-dom'
import Client from '../Client'
import marked from 'marked' //to make font bold and headings
import BlackLoading from '../assets/images/black-loader.gif'
import PricelistDetails from './PricelistDetails';

class PriceList extends Component{

    constructor(props) {
        super(props);
        this.state = {
          isOpen: false,
          pricelistpage: [],
        };
        this.openModal = this.openModal.bind(this);
      }
    
      componentDidMount() {
        Client
          .getEntries({
            content_type: "pricelist",
          })
          .then((entries) => {
            this.setState({ pricelistpage: entries.items[0] });
          });
      }
    
      getParsedMarkdown(pricelistDescription) {
        return {
          __html: marked(pricelistDescription, { sanitize: true }),
        };
      }
    
      openModal() {
        this.setState({ isOpen: true });
      }

    render(){
        return(
            <div>

                {/* Navigation bar */}
                <NavBar/>
                
                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>{this.state.pricelistpage.length === 0 ? (
                      <div align="left" className="pt-5">
                        <img src={BlackLoading} alt="Loader..." />
                      </div>
                    ) : (
                      <p>{this.state.pricelistpage.fields.pricelistTitle}</p>
                    )}</h1>
                                    <ul className="page-breadcrumb">
                                        <li><Link to="/">Home</Link></li>
                                        <li>Price List</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                <div className="page-wrapper section-space--inner--top--30">
                {/*About section start*/}
                <div className="about-section section-space--inner--bottom--50">
                <div className="container">
                    <div className="row row-25 align-items-center">
                    <div className="col-lg-12 col-12 mb-30">
                        <div className="about-content-two">
                        <p>
                        {this.state.pricelistpage.length === 0 ? (
                      <div align="left" className="pt-5">
                        <img src={BlackLoading} alt="Loader..." />
                      </div>
                    ) : (
                      //this code is for if you have heading in your content it will work
                      <div>
                      <h3 style={{textAlign:'justify'}} dangerouslySetInnerHTML = 
                                            {this.getParsedMarkdown(this.state.pricelistpage.fields.pricelistDetails)}></h3>
                                            <div>
                      <PricelistDetails/>

                      </div>
                      <p style={{textAlign:'justify'}} dangerouslySetInnerHTML = 
                                            {this.getParsedMarkdown(this.state.pricelistpage.fields.pricelistDescription)}></p>
                      </div>

                      //this code is for normal type if u have any heading it will not work
                      //<p>{this.state.aboutpage.fields.aboutDescription}</p>
                    )}
                        </p>

                        <Link to="ourbooks" className="ht-btn--default ht-btn--default--dark-hover section-space--top--20">Our Books</Link>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/*About section end*/}

                </div>


                {/* Footer */}
                <Footer/>

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
        )
    }
}


export default PriceList;