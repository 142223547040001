import React, { useState } from "react";

const ContactForm = () => {
  const [data, setData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const { name, bookname, subject, email, address, contact, message } = data;

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://v1.nocodeapi.com/vijayabooks/google_sheets/ILDHTuGnKJFrdWoK?tabId=Sheet1",
        {
          method: "Post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify([
            [
              name,
              bookname,
              subject,
              email,
              address,
              contact,
              message,
              new Date().toLocaleString(),
            ],
          ]),
        }
      );
      await response.json();
      setData({
        ...data,
        name: "",
        bookname: "",
        subject: "",
        email: "",
        address: "",
        contact: "",
        message: "",
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <form id="contact-form" onSubmit={handleSubmit}>
      <div className="row row-10">
        <div className="col-md-6 col-12 section-space--bottom--20">
          <input
            name="name"
            type="text"
            placeholder="Your Name"
            autoComplete="off"
            value={name}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-6 col-12 section-space--bottom--20">
          {/* <label>
            Select Book:
            <select name="bookname" value={bookname} onChange={handleChange}>
              <option value="Practical Navigation">Practical Navigation</option>
              <option value="Marine Meteorology">Marine Meteorology</option>
              <option value="Shipborne Radar">Shipborne Radar</option>
              <option value="Ship Stability at Operational Level">Ship Stability at Operational Level</option>
              <option value="Bridge Equipment Charts and Publications">Bridge Equipment Charts and Publications</option>
              <option value="Ship Stability at the Management Level">Ship Stability at the Management Level</option>
              <option value="Nautical Watch Keeping">Nautical Watch Keeping</option>
              <option value="Spherical Trigonometry">Spherical Trigonometry</option>
            </select>
          </label> */}
          <input
            name="bookname"
            type="text"
            placeholder="Book Names"
            autoComplete="off"
            value={bookname}
            onChange={handleChange}
          />
        </div>

        <div className="col-md-6 col-12 section-space--bottom--20">
          <input
            name="subject"
            type="text"
            placeholder="Subject"
            autoComplete="off"
            value={subject}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-6 col-12 section-space--bottom--20">
          <input
            name="email"
            type="email"
            placeholder="Email"
            autoComplete="off"
            value={email}
            onChange={handleChange}
          />
        </div>

        <div className="col-md-6 col-12 section-space--bottom--20">
          <input
            name="address"
            type="text"
            placeholder="Address for proposed delivery by courier"
            autoComplete="off"
            value={address}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-6 col-12 section-space--bottom--20">
          <input
            name="contact"
            type="number"
            placeholder="Contact No."
            autoComplete="off"
            value={contact}
            onChange={handleChange}
          />
        </div>

        <div className="col-12">
          <textarea
            name="message"
            placeholder="Your Message"
            defaultValue={""}
            autoComplete="off"
            value={message}
            onChange={handleChange}
          />
        </div>
        <div className="col-12">
          <button type="submit">Send Message</button>
        </div>
      </div>
    </form>
  );
};

export default ContactForm;
