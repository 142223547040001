import React, {Component} from 'react';
import {animateScroll as scroll } from 'react-scroll';
import {Link} from 'react-router-dom';
import FooterLogo from '../assets/images/vijaya-publications-f-logo.png'

class Footer extends Component{

    
    constructor(props){
        super(props);
        this.state = {};
        this.handleScroll = this.handleScroll.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
    }

    handleScroll() {
        if(this.mount){
            this.setState({scroll: window.scrollY});
        }
    }

    scrollToTop (){
        scroll.scrollToTop();
    }
  
    componentDidMount() {
        this.mount = true;
        const el = document.querySelector('nav');
        this.setState({top: el.offsetTop, height: el.offsetHeight});
        window.addEventListener('scroll', this.handleScroll);
    }
    
    componentDidUpdate() {
        this.state.scroll > this.state.top ? 
        document.getElementById("scroll-top").classList.add("show") :
        document.getElementById("scroll-top").classList.remove("show") ;
    }

    componentWillUnmount(){
        this.mount = false;
    }

    render(){
        return(
            <div>
                {/*====================  footer area ====================*/}
                <div className="footer-area dark-bg">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-content-wrapper section-space--inner--50">
                            <div className="row">
                                <div className="col-xl-3 col-lg-3 col-md-12">
                                {/* footer intro wrapper */}
                                <div className="footer-intro-wrapper">
                                    <div className="footer-logo">
                                    <Link to={`${process.env.PUBLIC_URL}/home-one`}>
                                        <img src={FooterLogo} className="img-fluid" alt="" />
                                    </Link>
                                    </div>
                                    <div className="footer-desc">
                                    <p>NUTSHELL SERIES By: Capt. H. SUBRAMANIAM F.R.Met.S., M.R.I.N., F.N.I., F.C.M.M.I., M.I. Mar.Tech., M.I.Met.S., Extra Master (UK)</p>
                                    <Link to="about-us">About Us</Link>
                                    </div>
                                </div>
                                </div>
                                <div className="col-xl-2 offset-xl-1 col-lg-3 col-md-4">
                                {/* footer widget */}
                                <div className="footer-widget">
                                    <h4 className="footer-widget__title">USEFUL LINKS</h4>
                                    <ul className="footer-widget__navigation">
                                    <li><Link to={`${process.env.PUBLIC_URL}/`}>Home</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/ourbooks`}>Books</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/about-us`}>About Us</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/price-list`}>Price List</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/where-to-buy`}>Where to Buy</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/contact-us`}>Contact Us</Link></li>
                                    </ul>
                                </div>
                                </div>
                                
                                <div className="col-xl-4 offset-xl-1 col-lg-3 col-md-4">
                                {/* footer widget */}
                                <div className="footer-widget mb-0">
                                    <h4 className="footer-widget__title">CONTACT US</h4>
                                    <div className="footer-widget__content">
                                    <p className="address">101, Chaitra Heritage, 550 11th Road, Chembur, Mumbai 400 071.</p>
                                    
                                    <ul className="contact-details">
                                        <li><span>Mobile:</span><a href="tel:+919867144701">+91 98671 44701</a></li>
                                        <li><span>Email:</span><a href="mailto:info@vijayabooks.com">info@vijayabooks.com</a></li>
                                    </ul>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                        <div className="footer-copyright-wrapper">
                        <div className="footer-copyright text-center">
                        © 2021 All right reserved - Vijaya Publications. Site Developed by SangamWorks +91-9819710811
                        </div>
                    </div>
                </div>
                {/*====================  End of footer area  ====================*/}
                {/*====================  scroll top ====================*/}
                <button className="scroll-top" id="scroll-top" onClick={this.scrollToTop}>
                    <i className="ion-android-arrow-up" />
                </button>
                {/*====================  End of scroll top  ====================*/}
            </div>
        )
    }
}


export default Footer;