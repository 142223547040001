import React, {Component} from 'react';
import NavBar from './components/NavBar';
//import HeroSliderTwo from './components/HeroSliderTwo';
import ServiceTab from './components/ServiceTab';
import TestimonialSlider from './components/TestimonialSlider';
import BrandLogoSlider from './components/BrandLogoSlider';
import Footer from './components/Footer';
import MobileMenu from './components/MobileMenu';
import BooksList from './service/BooksList';

class HomeTwo extends Component{
    render(){
        
        return(
            <div>
                
                {/* Navigation bar */}
                <NavBar/>
                
                {/* Hero slider */}
                {/* <HeroSliderTwo/> */}
                
                {/* Project Slider */}
                <BooksList/>
                
                {/* Testimonial Slider */}
                <TestimonialSlider/>


                {/* Service Tab */}
                <ServiceTab/>

                {/* Brand logo */}
                <BrandLogoSlider background = "grey-bg" />

                {/* Footer */}
                <Footer/>

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
        )
    }
}


export default HomeTwo;