import React, {Component} from 'react';
import NavBar from '../components/NavBar';
//import FeatureIcon from '../components/FeatureIcon';
//import Funfact from '../components/Funfact';
//import TeamMemberGrid from '../components/TeamMemberGrid';
//import TestimonialSlider from '../components/TestimonialSlider';
import BrandLogoSlider from '../components/BrandLogoSlider';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
//import ModalVideo from 'react-modal-video'

import {Link} from 'react-router-dom'
import Client from '../Client'
import marked from 'marked' //to make font bold and headings
import BlackLoading from '../assets/images/black-loader.gif'

class About extends Component{

    constructor(props) {
        super(props);
        this.state = {
          isOpen: false,
          aboutuspage: [],
        };
        this.openModal = this.openModal.bind(this);
      }
    
      componentDidMount() {
        Client
          .getEntries({
            content_type: "aboutVijayaPublication",
          })
          .then((entries) => {
            this.setState({ aboutuspage: entries.items[0] });
          });
      }
    
      getParsedMarkdown(aboutVpDescription) {
        return {
          __html: marked(aboutVpDescription, { sanitize: true }),
        };
      }
    
      openModal() {
        this.setState({ isOpen: true });
      }

    render(){
        return(
            <div>

                {/* Navigation bar */}
                <NavBar/>
                
                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>{this.state.aboutuspage.length === 0 ? (
                      <div align="left" className="pt-5">
                        <img src={BlackLoading} alt="Loader..." />
                      </div>
                    ) : (
                      <p>{this.state.aboutuspage.fields.aboutVpTitle}</p>
                    )}</h1>
                                    <ul className="page-breadcrumb">
                                        <li><Link to="/">Home</Link></li>
                                        <li>About Us</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                <div className="page-wrapper section-space--inner--top--30">
                {/*About section start*/}
                <div className="about-section section-space--inner--bottom--50">
                <div className="container">
                    <div className="row row-25 align-items-center">
                    {/* <div className="col-lg-6 col-12 mb-30">
                        <div className="about-image-two">
                        <img src="assets/img/about/about-3.jpg" alt="" />
                            <span className="video-popup">
                                <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='OrS-93U4AYQ' onClose={() => this.setState({isOpen: false})} />
                                <button onClick={this.openModal}>
                                    <i className="ion-ios-play-outline" />
                                </button>
                            </span>
                        </div>
                    </div> */}
                    <div className="col-lg-12 col-12 mb-30">
                        <div className="about-content-two">
                        <p>
                        {this.state.aboutuspage.length === 0 ? (
                      <div align="left" className="pt-5">
                        <img src={BlackLoading} alt="Loader..." />
                      </div>
                    ) : (
                      //this code is for if you have heading in your content it will work
                      <div
                        align="left"
                        dangerouslySetInnerHTML={this.getParsedMarkdown(
                          this.state.aboutuspage.fields.aboutVpDescription
                        )}
                      ></div>

                      //this code is for normal type if u have any heading it will not work
                      //<p>{this.state.aboutpage.fields.aboutDescription}</p>
                    )}
                        </p>
                        <Link to="ourbooks" className="ht-btn--default ht-btn--default--dark-hover section-space--top--20">Our Books</Link>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/*About section end*/}
                
                {/* Feature Icon */}
                {/* <FeatureIcon background = "grey-bg"/> */}

                {/*About section start*/}
                {/* <div className="about-section section-space--inner--120">
                <div className="container">
                    <div className="about-wrapper row">
                    <div className="col-sm-6 col-12 order-1 order-lg-2">
                        <div className="about-image about-image-1">
                        <img src="assets/img/about/about-1.jpg" alt="" />
                        </div>
                    </div>
                    <div className="col-sm-6 col-12 order-2 order-lg-3">
                        <div className="about-image about-image-2">
                        <img src="assets/img/about/about-2.jpg" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 order-3 order-lg-1">
                        <div className="about-content about-content-1">
                        <h1><span>50</span>Years of Experience</h1>
                        <p>ligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor ellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum atibus saepe eveniet ut et voluptates repudiandae sint et molestiae</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 order-4">
                        <div className="about-content about-content-2">
                        <p>ligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor ellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum atibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapie</p>
                        <Link to={`${process.env.PUBLIC_URL}/contact-us`} className="ht-btn--default ht-btn--default--dark-hover section-space--top--20">Contact us</Link>
                        </div>
                    </div>
                    </div>
                </div>
                </div> */}
                {/*About section end*/}
                
                
                {/* Brand logo */}
                <BrandLogoSlider background = "" />

                </div>


                {/* Footer */}
                <Footer/>

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
        )
    }
}


export default About;