import React, {Component} from 'react';
import Client from '../Client'
import {Link} from 'react-router-dom'

class BooksList extends Component{
    constructor(props) {
        super(props)
        this.state = {
            bookspage: []
        }
    }

    componentDidMount(){
        Client.getEntries({
            'content_type': 'books', 
            'order': 'sys.createdAt'

        }).then((entries)=>{
            this.setState({bookspage: entries.items})
        })
    }

    render(){

        let Datalist = this.state.bookspage.map((val, i) => {
            return(
                <div className="col-lg-3 col-md-4 col-12 section-space--bottom--30" key={i}>
                    <div className="service-grid-item">
                    <div className="service-grid-item__image">
                        <div className="service-grid-item__image-wrapper">
                        <Link to={`${process.env.PUBLIC_URL}/ourbookdetails/${val.fields.slug}`}>
                            <img src={val.fields.bookimg.fields.file.url} className="img-fluid" alt="Nutshell Series Books" />
                        </Link>
                        </div>
                    </div>
                    <div className="service-grid-item__content">
                        <strong>
                        <Link to={`${process.env.PUBLIC_URL}/ourbookdetails/${val.fields.slug}`}>{val.fields.booktitle}</Link>
                        </strong>
                        <p className="subtitle">{val.fields.bookExcerpt}</p>
                        <Link to={`${process.env.PUBLIC_URL}/ourbookdetails/${val.fields.slug}`} className="see-more-link">SEE MORE</Link>
                    </div>
                    </div>
                </div>
            )
        });

        return(
            <div>

                {/*====================  service page content ====================*/}
                <div className="page-wrapper section-space--inner--50">
                {/*Service section start*/}
                <div className="service-section">
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                        <div className="service-item-wrapper">
                            <div className="row">
                                {Datalist}
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/*Service section end*/}
                </div>

                {/*====================  End of service page content  ====================*/}

            </div>
        )
    }
}

export default BooksList;