import React, {Component} from 'react';
import NavBar from '../components/NavBar';
//import Sidebar from './components/Sidebar';
//import BrandLogoSlider from '../components/BrandLogoSlider';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
//import ServiceGallery from './components/ServiceGallery';
import {Link} from 'react-router-dom'
import Client from '../Client'
import marked from 'marked' //to make font bold and headings
import BlackLoading from '../assets/images/black-loader.gif'

class OurBookDetails extends Component{
    constructor(props) {
        super(props)
    
        this.state = {
             article:[]
        }
    }

    componentDidMount(){
        const slug = this.props.match.params.slug
        if(slug){
            Client.getEntries({
                'content_type': 'books', 
                'fields.slug': slug
    
            }).then((entries)=>{
                this.setState({article: entries.items[0]})
            })

        }
        
    }

    //to make font bold and headings
    getParsedMarkdown(bookDescription){
        return{
            __html:marked(bookDescription, {sanitize:true})
        }
    }
    //to make font bold and headings - end
    
    redirectToTarget=()=>{
        this.props.history.push('/ourbooks')
    }

    render(){

        return(
            <div>
                {/* Navigation bar */}
                <NavBar/>

                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                <h1>{this.state.article.length === 0 ?
                                        <div align="center"><img src={BlackLoading} alt="Loading..." /></div> :
                                    <div>
                                        <div>
                                            {this.state.article.fields.booktitle}
                                        </div>
                                        </div>
                                    }</h1>
                                    <ul className="page-breadcrumb">
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to={`${process.env.PUBLIC_URL}/ourbooks`}>Book</Link></li>
                                        <li>Book Details</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                <div className="page-wrapper section-space--inner--50">
                    {/*Service section start*/}
                    <div className="service-section">
                        <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-12 order-1">
                            <div className="service-details">
                                {/* service gallery */}
                                {/* <ServiceGallery/> */}
                                <div className="content section-space--top--30">
                                <div className="row">
                                    <div className="col-12">
                                    {this.state.article.length === 0 ?
                                        <div align="center"><img src={BlackLoading} alt="Loading..." /></div> :
                                    <div className="row">
                                        <div className="col-md-12">
                                        
                                            <img src={this.state.article.fields.bookimg.fields.file.url} className="img-blog img-fluid" alt="{blogTitle}" style={{float:'left', margin:'0 20px 0 0'}}/>
                                            <p style={{textAlign:'justify'}} dangerouslySetInnerHTML = 
                                            {this.getParsedMarkdown(this.state.article.fields.bookDescription)}></p>
                                            <p>
                                            <button className="btn btn-primary"><Link to="#" onClick={this.redirectToTarget}>Back to Books</Link></button> . 
                                            <button className="btn btn-primary"><a href={this.state.article.fields.eBookLink} target={'_blank'}>Buy eBook on Amazon</a></button>
                                            </p>
                                            <p>..</p>
                                            
                                        </div>
                                        </div>
                                    }
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            {/* <div className="col-lg-4 col-12 order-2">
                                <Sidebar />
                            </div> */}
                        </div>
                        </div>
                    </div>
                    {/*Service section end*/}
                    </div>

                {/* Brand logo */}
                {/* <BrandLogoSlider background = "grey-bg" /> */}

                {/* Footer */}
                <Footer/>

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
        )
    }
}


export default OurBookDetails;